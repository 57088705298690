import * as tslib_1 from "tslib";
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user.model';
import { ReplaySubject, Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@nebular/auth";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
var UserService = /** @class */ (function () {
    function UserService(authService, http, router) {
        var _this = this;
        this.authService = authService;
        this.http = http;
        this.router = router;
        this.user = new User;
        this._user = new ReplaySubject(1);
        this.user$ = this._user.asObservable();
        this.search = function (q, m, b) {
            var params = { q: q, m: m };
            if (b) {
                params.b = b;
            }
            return _this.http.get('users/search', { params: params });
        };
        this.authService
            .onAuthenticationChange()
            .subscribe(function (isAuthenticated) {
            if (!isAuthenticated) {
                _this.user = null;
                _this._user.next(null);
                return;
            }
            _this.reloadUser().subscribe(function (user) {
                var isClientPreview = sessionStorage.getItem('isClientPreview');
                if (isClientPreview === 'true') {
                    _this.router.navigate(['/app/score']);
                }
                else if (isClientPreview === 'false') {
                    sessionStorage.removeItem('isClientPreview');
                    _this.router.navigate(['/business/tags/operate']);
                    location.reload();
                }
            });
        });
    }
    UserService.prototype.one = function (id) {
        return new User({ id: +id });
    };
    UserService.prototype.get = function (user) {
        return this.http.get("users/" + user.id)
            .pipe(map(function (response) { return new User(response); }));
    };
    UserService.prototype.getUser = function (force) {
        if (force === void 0) { force = false; }
        if (localStorage.getItem('user') === null || force || sessionStorage.getItem('isClientPreview')) {
            return this.reloadUser();
        }
        else {
            this.user = new User(JSON.parse(localStorage.getItem('user')));
            this.user.role = sessionStorage.getItem('isClientPreview') ? 'user' : this.user.role;
            this._user.next(this.user);
            return Observable.of(this.user);
        }
    };
    UserService.prototype.reloadUser = function () {
        var _this = this;
        return this.http.get('user')
            .pipe(map(function (response) {
            if (response.success) {
                return new User(tslib_1.__assign({}, response.data, { role: sessionStorage.getItem('isClientPreview') ? 'user' : response.data.role }));
            }
            else {
                _this.router.navigate(['auth/logout']);
                return;
            }
        }))
            .pipe(map(function (user) {
            _this.user = user;
            _this._user.next(_this.user);
            return _this.user;
        }));
    };
    UserService.prototype.retrieveToken = function (params) {
        return this.http.get('auth/validateToken', { params: params })
            .pipe(map(function (response) {
            response.token = params.token;
            return response;
        }));
    };
    UserService.prototype.current = function () {
        return this.user$
            .filter(function (user) { return user !== null; });
    };
    UserService.prototype.clear = function () {
        this.user = null;
        this._user.next(this.user);
        localStorage.removeItem('user');
    };
    UserService.prototype.requestResetPassword = function (data) {
        return this.http.post('oauth/password/email', data);
    };
    // Todo: Ver si esto se esta usando
    /*checkPassword(token) {
      return this.http.post('oauth/password/reset', { token });
    }
  
    resetPassword(token, newPassword) {
      return this.http.post('oauth/password/reset', { token });
    }*/
    UserService.prototype.resetPassword = function (password, password_confirmation) {
        return this.http.put('users/password', { password: password, password_confirmation: password_confirmation });
    };
    UserService.prototype.resendVerification = function (id, business_id) {
        return this.http.post("users/resendVerification/" + id, { id: id, business_id: business_id });
    };
    UserService.prototype.activateEmail = function (operator) {
        return this.http.post("users/activateEmail/" + operator.id, {});
    };
    UserService.prototype.save = function (user, send_notification) {
        if (send_notification === void 0) { send_notification = false; }
        var method = user.id ? 'put' : 'post';
        var url = 'users';
        if (method === 'put') {
            url += '/' + user.id;
        }
        return this.http.request(method, url, { body: tslib_1.__assign({}, user, { send_notification: send_notification }) });
    };
    UserService.prototype.unsubscribe = function (email, type) {
        return this.http.get('notifications/unsubscribe', { params: { email: email, type: type } });
    };
    UserService.prototype.loadUsers = function (id, file, sendNotification) {
        if (sendNotification === void 0) { sendNotification = false; }
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('sendNotification', sendNotification.toString());
        return this.http.post("users/loadsheet/" + id, formData);
    };
    UserService.prototype.updateEmail = function (userId, email) {
        return this.http.put("users/" + userId + "/email", { email: email });
    };
    UserService.prototype.updateDni = function (userId, dni) {
        return this.http.put("users/" + userId + "/dni", { dni: dni });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.NbAuthService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };

import * as tslib_1 from "tslib";
import { NbAuthResult, NbAuthService, NbLoginComponent } from '@nebular/auth';
import { ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../_services/user.service';
import { BusinessService } from '../../_services/business.service';
import { ClientService } from '../../_services/client.service';
import { OperatorService } from '../../_services/operator.service';
var LoginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginComponent, _super);
    function LoginComponent(service, options, cd, router, route, http, userService, operatorService, clientService, businessService) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, cd, router) || this;
        _this.service = service;
        _this.options = options;
        _this.cd = cd;
        _this.router = router;
        _this.route = route;
        _this.http = http;
        _this.userService = userService;
        _this.operatorService = operatorService;
        _this.clientService = clientService;
        _this.businessService = businessService;
        _this.socialAlive = true;
        _this.showNativeLogin = false;
        _this.hideRegister = false;
        _this.isTuNegocio = false;
        _this.isSuperAdmin = false;
        _this.loginText = 'Ingresá con tu email y contraseña';
        _this.loginWithFacebookOrGoogleEnabled = false;
        _this.isPasswordVisible = false;
        _this.then = _this.route.snapshot.params.then;
        var status = _this.route.snapshot.params.status;
        if (status === 'verification_pending') {
            _this.showNativeLogin = true;
            _this.messages.push('Te hemos enviado un correo con las instrucciones para continuar.');
        }
        else if (status === 'verified') {
            _this.showNativeLogin = true;
            _this.messages.push('Activación exitosa. Por favor ingresá con tus datos.');
        }
        else if (status === 'points-loaded') {
            _this.showNativeLogin = true;
            _this.messages.push('Activación exitosa y puntos agregados a la cuenta. Por favor ingresá con tus datos.');
        }
        else if (status === 'business_registered') {
            _this.messages.push('¡Listo! Ahora por favor ingresá con los datos que acabás de ingresar.');
        }
        return _this;
    }
    LoginComponent.prototype.togglePasswordVisibility = function () {
        this.isPasswordVisible = !this.isPasswordVisible;
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isTuNegocio = this.businessService.getSubdomain() === 'tunegocio';
        this.isSuperAdmin = this.businessService.getSubdomain() === 'mi';
        this.route.data.subscribe(function (data) {
            _this.business = data.business;
            if (_this.business.hide_register) {
                _this.showNativeLogin = true;
                _this.hideRegister = true;
            }
            _this.loginText =
                _this.business.show_google_login || _this.business.show_facebook_login
                    ? 'O '
                    : '';
            _this.loginWithFacebookOrGoogleEnabled =
                _this.business.show_google_login || _this.business.show_facebook_login;
            if (_this.business.login_with_dni_text) {
                _this.loginText += 'Ingresá con tu DNI y Contraseña';
            }
            else if (_this.business.login_with_cuit_text) {
                _this.loginText += 'Ingresá con tu CUIT y Contraseña';
            }
            else {
                _this.loginText += 'Ingresá con tu E-mail y Contraseña';
            }
        });
        this.user.tos = true;
    };
    LoginComponent.prototype.demoCommerceLogin = function () {
        this.user.email = 'tunegocio@tiendadepuntos.com';
        this.user.password = 'demo1234';
        this.login();
    };
    LoginComponent.prototype.demoClientLogin = function () {
        this.user.email = 'clientedemo@tiendadepuntos.com';
        this.user.password = 'demo1234';
        this.login();
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.errors = [];
        this.messages = [];
        this.submitted = true;
        this.service.authenticate(this.strategy, this.user).subscribe(function (result) {
            /* if ( this.isTuNegocio() ) {
                localStorage.setItem('status_login', 'AFTER_LOGIN');
              } */
            _this.submitted = false;
            _this.operatorService.clear();
            _this.clientService.clear();
            _this.userService.clear();
            _this.businessService.clear();
            if (result.isSuccess()) {
                _this.messages = result.getMessages();
                if (_this.then) {
                    _this.router.navigate([_this.then], {
                        queryParams: { welcome_popup: 'show' },
                    });
                }
                else {
                    _this.router.navigate(['/'], {
                        queryParams: { welcome_popup: 'show' },
                    });
                }
            }
            else {
                switch (result.getErrors()[0]) {
                    case 'invalid_credentials':
                        _this.errors.push('El usuario o la contraseña ingresados no son válidos');
                        break;
                    case 'suspended_account':
                        _this.errors.push('Cuenta suspendida');
                        break;
                    default:
                        _this.errors = result.getErrors();
                        break;
                }
            }
            _this.cd.detectChanges();
        });
    };
    LoginComponent.prototype.socialLogin = function (social) {
        var _this = this;
        localStorage.setItem('social_redirect_uri', this.route.snapshot.params.then);
        this.service
            .authenticate(social)
            .pipe(takeWhile(function () { return _this.socialAlive; }))
            .subscribe(function (authResult) { });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.socialAlive = false;
    };
    return LoginComponent;
}(NbLoginComponent));
export { LoginComponent };

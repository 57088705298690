import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Business } from '../_models/business.model';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { HistoryService } from './history.service';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { environment } from '../../environments/environment';
import { BusinessBranch } from '../_models/business_branch.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
import * as i3 from "./history.service";
var BusinessService = /** @class */ (function () {
    function BusinessService(http, document, historyService) {
        this.http = http;
        this.document = document;
        this.historyService = historyService;
        this.business = new Business();
        this._business = new ReplaySubject(1);
        this.business$ = this._business.asObservable();
        this.subdomain = this.getSubdomain();
        this.localStorageKey = "business_" + this.subdomain;
        this.getCurrent();
    }
    BusinessService.prototype.setBusiness = function (business) {
        this.business = business;
        this._business.next(this.business);
        localStorage.setItem(this.localStorageKey, JSON.stringify(business));
    };
    BusinessService.prototype.one = function (id) {
        var business = new Business();
        if (id) {
            business.id = id;
        }
        return business;
    };
    BusinessService.prototype.get = function (business) {
        return this.http.get('businesses/' + business.id).pipe(map(function (response) {
            return new Business(response);
        }));
    };
    BusinessService.prototype.getBySlug = function (slug) {
        return this.http.get('businesses/slug/' + slug).pipe(map(function (response) {
            return new Business(response);
        }));
    };
    BusinessService.prototype.getPaginationResult = function (page, per_page, name) {
        var params = { page: page, per_page: per_page };
        if (name) {
            params.name = name;
        }
        return this.http.get('businesses', { params: params }).pipe(map(function (response) {
            var pagination_result = response;
            pagination_result.data = pagination_result.data.map(function (business) {
                return new Business(business);
            });
            return new PaginationResult(pagination_result);
        }));
    };
    BusinessService.prototype.all = function (params) {
        return this.http.get('businesses', { params: params }).pipe(map(function (response) {
            return response.map(function (business) {
                return new Business(business);
            });
        }));
    };
    BusinessService.prototype.save = function (business) {
        var method = business.id ? 'put' : 'post';
        var url = 'businesses';
        if (method === 'put') {
            url += '/' + business.id;
        }
        return this.http.request(method, url, { body: business });
    };
    BusinessService.prototype.delete = function (business) {
        return this.http.delete("businesses/" + business.id);
    };
    BusinessService.prototype.resendVerification = function (business) {
        return this.http.put("businesses/" + business.id + "/activation", business);
    };
    BusinessService.prototype.getBusinessFromStorage = function () {
        return new Business(JSON.parse(localStorage.getItem(this.localStorageKey)));
    };
    BusinessService.prototype.getCurrent = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (this.subdomain === 'mi') {
            this.business = new Business();
            this._business.next(this.business);
            return Observable.of(this.business);
        }
        if (this.subdomain === 'www') {
            this.document.location.href = this.document.location.href.replace('www.', '');
        }
        if (localStorage.getItem(this.localStorageKey) === null || force) {
            return this.reloadBusiness().pipe(catchError(function (err) {
                if (err.status === 404) {
                    _this.document.location.href = '/notfound.html';
                }
                return throwError('Invalid subdomain!');
            }));
        }
        else {
            this.business = new Business(JSON.parse(localStorage.getItem(this.localStorageKey)));
            this._business.next(this.business);
            return Observable.of(this.business);
        }
    };
    BusinessService.prototype.reloadBusiness = function () {
        var _this = this;
        if (!this.getSubdomain()) {
            return Observable.of(this.one());
        }
        return this.getBySlug(this.getSubdomain()).pipe(tap(function (business) {
            return localStorage.setItem(_this.localStorageKey, JSON.stringify(business));
        }), map(function (business) {
            _this.business = business;
            _this._business.next(_this.business);
            return _this.business;
        }));
    };
    BusinessService.prototype.current = function () {
        return this.getCurrent();
    };
    BusinessService.prototype.clear = function () {
        if (this.business) {
            localStorage.removeItem("business_" + this.business.slug);
        }
        this.business = null;
        this._business.next(this.business);
    };
    BusinessService.prototype.getSubdomain = function () {
        var subdomain = window.location.hostname
            .replace(environment.frontendBaseUrl.split('//')[1], '')
            .split('.');
        if (subdomain.length > 1) {
            return subdomain[0];
        }
        return null;
    };
    BusinessService.prototype.getLink = function (business) {
        return business.link;
    };
    BusinessService.prototype.getCategories = function () {
        return this.http.get("businesses/categories").pipe(map(function (categories) {
            return categories.map(function (category) {
                return category;
            });
        }));
    };
    BusinessService.prototype.getPlans = function () {
        return this.http.get("business-plans").pipe(map(function (plans) {
            return plans.map(function (plan) {
                return plan;
            });
        }));
    };
    BusinessService.prototype.getHistory = function (business, params) {
        if (params === void 0) { params = {}; }
        params = { params: params };
        if (business.id) {
            params.business = business.id;
        }
        return this.historyService
            .get(params)
            .pipe(map(function (events) {
            return events.map(function (event) {
                return event.type === 'purchase' ? new Purchase(event) : new Read(event);
            });
        }));
    };
    BusinessService.prototype.getBranches = function (business) {
        var params = new HttpParams();
        if (business) {
            params.set('business_id', business.id.toString());
        }
        return this.http.get("branches").pipe(map(function (response) {
            return response;
        }));
    };
    BusinessService.prototype.suspended = function (status, business_id) {
        var suspended = status;
        return this.http.put("businesses/" + business_id + "/hired-plan/suspended", {
            suspended: suspended,
        });
    };
    BusinessService.prototype.renew = function (business_id) {
        return this.http.put("businesses/" + business_id + "/hired-plan/renew", {});
    };
    BusinessService.prototype.getAllBranches = function () {
        return this.http.get("businesses/branches").pipe(map(function (response) {
            var data = response.map(function (branch) {
                return new BusinessBranch(branch.name, branch.id, branch.enabled);
            });
            return data;
        }));
    };
    BusinessService.prototype.changeUserRole = function (business, user, role) {
        return this.http.put("businesses/" + business.id + "/user/" + user.id + "/role", {
            role: role,
        });
    };
    BusinessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessService_Factory() { return new BusinessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.HistoryService)); }, token: BusinessService, providedIn: "root" });
    return BusinessService;
}());
export { BusinessService };

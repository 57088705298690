import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { User } from '../../_models/user.model';
import { UserService } from '../../_services/user.service';
import { ChangePasswordComponent } from '../../_dialogs/change-password/change-password.component';
import { environment } from '../../../environments/environment';
import { BusinessService } from '../../_services/business.service';
import { Business } from '../../_models/business.model';
import { NotificationComponent } from '../../_dialogs/notification/notification.component';
import { CategoryChangeType } from '../../_models/category.model';
import { CalculatorComponent } from '../../_dialogs/calculator/calculator.component';
import { BusinessBranch } from '../../_models/business_branch.model';
import { darkenHexColor, hexToRgba } from '../../@core/utils/color-utils';
import { MaxDecimalsValidator } from '../../@core/validators/MaxDecimalsValidator';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(userService, formBuilder, businessService, dialogService, toastrService) {
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.businessService = businessService;
        this.dialogService = dialogService;
        this.toastrService = toastrService;
        this.editorConfigs = [];
        // * CAMBIABLE
        this.socialMediaLimit = 3;
        this.saving = false;
        this.submitted = false;
        this.growth = null;
        this.categoryChanges = [];
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo']] });
        this.userService.current().subscribe(function (user) {
            _this.user = user;
            _this.user.role === 'admin' || _this.user.role === 'user' ? _this.createAdminForm() : _this.createBusinessForm();
            _this.form.patchValue(_this.user);
            if (_this.user.business) {
                _this.branches = _this.user.business.branches;
            }
            if (_this.user.role !== 'admin' && _this.user.role !== 'business' && _this.user.birthday) {
                _this.form.get('birthday').disable();
            }
            else {
                _this.form.get('birthday').enable();
            }
        });
    };
    ProfileComponent.prototype.createAdminForm = function () {
        this.form = this.formBuilder.group({
            first_name: ['', [Validators.required]],
            last_name: [''],
            email: [{ value: '', disabled: true }, [Validators.required]],
            phone: [''],
            birthday: [''],
            score_amount_ratio: [''],
            money_ratio: [''],
            allow_categories: [false],
        });
    };
    ProfileComponent.prototype.createBusinessForm = function () {
        var _this = this;
        var limitSocialMedia = function (control, key) {
            var _a;
            if (!_this.form || !control.value)
                return null;
            var e = _this.hasSocialMediaError();
            if (e)
                return _a = {}, _a["limit_" + key] = true, _a;
            else {
                // this.wipeSocialMediaError();
                return null;
            }
        };
        this.form = this.formBuilder.group({
            business: this.formBuilder.group({
                name: ['', Validators.required],
                score_amount_ratio: [0, [Validators.required, Validators.min(0.000001), MaxDecimalsValidator(6)]],
                money_ratio: [0, [Validators.required, Validators.min(0), MaxDecimalsValidator(6)]],
                primary_color: ['#5b53f0', [Validators.required]],
                primary_negative_color: ['#ffffff', [Validators.required]],
                secondary_color: ['#009ef3', [Validators.required]],
                secondary_negative_color: ['#ffffff', [Validators.required]],
                navbar_color: ['#5b53f0', [Validators.required]],
                navbar_negative_color: ['#ffffff', [Validators.required]],
                logo: ['', [Validators.required]],
                logo_mini: ['', [Validators.required]],
                logo_login: ['', [Validators.required]],
                money_exchange_text: [''],
                whatsapp_message: ['¡Hola! Ingresá en el siguiente link y sumá {{puntos}} para llegar increíbles premios en {{comercio}} ➡️ {{link}}\n\n¡Muchas gracias!', function (control) {
                        var forbidden = !control.value.includes('{{link}}');
                        return forbidden ? { 'link': true } : null;
                    }],
                twitter_url: ['', [function (c) { return limitSocialMedia(c, 'twitter'); }]],
                facebook_url: ['', [function (c) { return limitSocialMedia(c, 'facebook'); }]],
                linkedin_url: ['', [function (c) { return limitSocialMedia(c, 'linkedin'); }]],
                instagram_url: ['', [function (c) { return limitSocialMedia(c, 'instagram'); }]],
                web_url: ['', [function (c) { return limitSocialMedia(c, 'web'); }]],
                category: [''],
                promo_popup_enabled_admin: [false],
                promo_popup_enabled: [false],
                promo_image: ['', []],
                promo_button_text: ['', []],
                promo_redirect_url: ['', []],
                show_facebook_login: [false, []],
                show_google_login: [false, []],
                send_birthday_reward: [false],
                make_client_email_mandatory_for_exchanges: [false],
                birthday_reward_points: [0, Validators.min(0)],
                purchase_emails: ['', function (control) {
                        if (!control.value)
                            return;
                        var emails = control.value.split(',');
                        var forbidden = emails.some(function (email) { return Validators.email(new FormControl(email)); });
                        return forbidden ? { 'emails': true } : null;
                    }],
                allow_support_message: [false],
                support_message: [''],
                update_levels_score_ratio: [false],
                allow_min_amount: [false, []],
                min_amount: [0, [Validators.min(0)]],
                allow_min_branches_reads: [false, []],
                min_branches_reads: [0, [Validators.min(0)]],
                allow_expiration_points: [false, []],
                expiration_points_days: [0, [Validators.min(0)]],
                email_tag_notification_subject: ['¡Tenés puntos para sumar en :business!', []],
                email_tag_notification_mainline: ['Recibiste puntos por tu consumo en :business.', []],
                tag_read_notification_subject: ['Sumaste :score puntos en :business', []],
                tag_read_notification_mainline: ['¡Felicitaciones! Sumaste :score puntos en tu cuenta en :business.', []],
                purchase_notification_subject: ['Canje exitoso de :product', []],
                purchase_notification_mainline: ['¡Seguí sumando puntos por tus consumos en :business para acceder a más premios increíbles!', []],
                unlocked_products_notification_subject: ['¡Ganaste un premio en :business!', []],
                unlocked_products_notification_mainline: ['Si querés ingresar a canjearlo podés hacerlo en cualquier momento pulsando el botón de abajo:', []],
                account_created_notification_subject: ['¡Completá tu registro en Tienda de Puntos!', []],
                account_created_notification_mainline: [':business te invitó a su programa de puntos ¡Felicitaciones!', []],
                levelup_notification_subject: ['¡Subiste de nivel en :business!', []],
                levelup_notification_mainline: ['¡Felicitaciones! Subiste de nivel en comercio ":business". Ahora sos nivel ":level" y vas a poder acceder nuevos beneficios.', []],
                direct_tag_whatsapp_notification: ['¡Sumaste puntos en :business!', []],
                allow_accumulate_points_rules: [false],
            }),
            first_name: ['', [Validators.required]],
            last_name: [''],
            fantasy_name: [''],
            email: [{ value: '', disabled: true }, [Validators.required]],
            phone: [''],
            birthday: [''],
            allow_categories: [false],
        });
    };
    ProfileComponent.prototype.isTuNegocio = function () {
        return this.businessService.getSubdomain() === 'tunegocio';
    };
    Object.defineProperty(ProfileComponent.prototype, "controls", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "businessControls", {
        get: function () {
            return this.form.get('business');
        },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.hasSocialMediaError = function () {
        var links = [
            this.form.get('business').get('twitter_url').value,
            this.form.get('business').get('facebook_url').value,
            this.form.get('business').get('linkedin_url').value,
            this.form.get('business').get('instagram_url').value,
            this.form.get('business').get('web_url').value,
        ].filter(Boolean);
        return links.length > this.socialMediaLimit;
    };
    ProfileComponent.prototype.wipeSocialMediaError = function () {
        this.form.get('business').get('twitter_url').setErrors({});
        this.form.get('business').get('facebook_url').setErrors({});
        this.form.get('business').get('linkedin_url').setErrors({});
        this.form.get('business').get('instagram_url').setErrors({});
        this.form.get('business').get('web_url').setErrors({});
    };
    Object.defineProperty(ProfileComponent.prototype, "businessLogoControls", {
        get: function () {
            return this.form.get('business').get('logo');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "businessLogoLoginControls", {
        get: function () {
            return this.form.get('business').get('logo_login');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "businessLogoMiniControls", {
        get: function () {
            return this.form.get('business').get('logo_mini');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileComponent.prototype, "businessPromoImageControls", {
        get: function () {
            return this.form.get('business').get('promo_image');
        },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.openDescPopup = function () {
        var texto = 'Esta sección te permite administrar tus datos basicos.', titulo = 'Perfil';
        this.dialogService.open(NotificationComponent, {
            context: {
                status: 'white',
                title: titulo,
                message: texto,
                showButton: false,
            },
            closeOnBackdropClick: true,
            closeOnEsc: true,
        });
    };
    ProfileComponent.prototype.changePassword = function () {
        this.dialogService.open(ChangePasswordComponent);
    };
    ProfileComponent.prototype.isProduction = function () {
        return environment.production;
    };
    ProfileComponent.prototype.openPopupBirthdayRewards = function () {
        var texto = "\n    <div style=\"max-width: 600px;\">\n    Activa esta opci\u00F3n para enviarle puntos a tus clientes cuando cumplan a\u00F1os. Estos puntos se enviaran autom\u00E1ticamente en su d\u00EDa tan especial.\n    </div>\n    ";
        this.dialogService.open(NotificationComponent, {
            context: {
                title: 'Cantidad de puntos a enviar por cumpleaños',
                status: 'white',
                message: texto,
                showButton: false,
            },
            closeOnBackdropClick: true,
            closeOnEsc: true,
        });
    };
    ProfileComponent.prototype.openPopupPuntosPesos = function () {
        var texto = "\n    <div style=\"max-width: 600px;\">\n    Indica en este campo la cantidad de puntos que entregas por cada peso que gaste tu cliente en tu comercio.\n    </br>\n    </br>\n    \u00BFAumentaste tus precios? Puedes reducir esta variable en la misma proporci\u00F3n para que tus clientes no lleguen m\u00E1s f\u00E1cilmente\n    a tus premios. Por ejemplo, si entregabas 10 puntos por peso y aumentaste un 10%, ahora puedes entregar 9 puntos por peso.\n    </div>\n    ";
        this.dialogService.open(NotificationComponent, {
            context: {
                title: 'Puntos a entregar por $ cobrado',
                status: 'white',
                message: texto,
                showButton: false,
            },
            closeOnBackdropClick: true,
            closeOnEsc: true,
        });
    };
    ProfileComponent.prototype.openPopupLevelsUpdate = function () {
        var texto = "\n    <div style=\"max-width: 600px;\">\n    Si se selecciona este campo, las modificaciones establecidas a los puntos a entregar por $ cobrado se aplicar\u00E1n tambi\u00E9n para los niveles creados.\n    </br>\n    </br>\n    La actualizaci\u00F3n de la relaci\u00F3n $/puntos en cada nivel, corresponder\u00E1 a una reducci\u00F3n o aumento porcentual, inversamente equivalente a la modificaci\u00F3n que se realice en la relaci\u00F3n $/puntos general de la empresa.\n    </br>\n    </br>\n    <strong>IMPORTANTE: El cambio en la relaci\u00F3n para los niveles solo funcionar\u00E1 si se utiliza la calculadora.</strong>\n    </div>\n    ";
        this.dialogService.open(NotificationComponent, {
            context: {
                title: 'Modificar relación $/puntos en niveles',
                status: 'white',
                message: texto,
                showButton: false,
            },
            closeOnBackdropClick: true,
            closeOnEsc: true,
        });
    };
    ProfileComponent.prototype.openPopupMoneyRatio = function () {
        var texto = "\n    <div style=\"max-width: 600px;\"> Este campo indica cuanto Dinero se puede canjear por cada punto acumulado. </div>\n    ";
        this.dialogService.open(NotificationComponent, {
            context: {
                title: 'Canje de Puntos por Dinero',
                status: 'white',
                message: texto,
                showButton: false,
            },
            closeOnBackdropClick: true,
            closeOnEsc: true,
        });
    };
    ProfileComponent.prototype.delete = function (idx) {
        var _this = this;
        var oldChange = this.categoryChanges.findIndex(function (c) { return c.name === _this.user.business.product_categories[idx].name; });
        if (oldChange === -1) {
            this.categoryChanges.push({
                type: CategoryChangeType.Remove,
                id: this.user.business.product_categories[idx].id,
                name: this.user.business.product_categories[idx].name,
            });
        }
        else {
            this.categoryChanges.splice(oldChange, 1);
        }
        this.user.business.product_categories.splice(idx, 1);
        this.form.markAsDirty();
    };
    ProfileComponent.prototype.addCategory = function () {
        var _this = this;
        var oldChange = this.categoryChanges.findIndex(function (c) { return c.name === _this.newCategory; });
        if (oldChange === -1) {
            this.categoryChanges.push({
                type: CategoryChangeType.Add,
                name: this.newCategory,
            });
        }
        else {
            this.categoryChanges.splice(oldChange, 1);
        }
        this.user.business.product_categories.push({
            name: this.newCategory,
        });
        this.newCategory = '';
        this.form.markAsDirty();
    };
    ProfileComponent.prototype.addBranch = function () {
        var _this = this;
        if (this.branches == null)
            this.branches = [];
        if (!this.newBranch || this.newBranch.length === 0) {
            this.toastrService.danger('No se puede crear una sucursal sin nombre.', 'Ups!');
            return;
        }
        if (this.branches.find(function (b) { return b.name === _this.newBranch; })) {
            this.toastrService.danger('No se pueden crear 2 o mas sucursales con el mismo nombre.', 'Ups!');
            return;
        }
        this.branches.push(new BusinessBranch(this.newBranch));
        this.newBranch = '';
        this.form.markAsDirty();
    };
    ProfileComponent.prototype.deleteBranch = function (branch) {
        branch.state = 'deleted';
        this.form.markAsDirty();
    };
    ProfileComponent.prototype.save = function () {
        var _this = this;
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.saving = true;
        if (this.user.role === 'business') {
            this.business = new Business(this.form.get('business').value);
            this.business.product_categories = this.user.business.product_categories;
        }
        this.userService.save(Object.assign(this.user, new User(this.form.value))).subscribe(function () {
            if (_this.user.role === 'business') {
                _this.business.id = _this.user.business_id;
                var data = tslib_1.__assign({}, _this.business, { score_amount_ratio: "" + _this.businessControls.get('score_amount_ratio').value, growth: _this.growth, product_categories: _this.business.product_categories, categoryChanges: _this.categoryChanges, branches: _this.branches });
                _this.businessService.save(data).subscribe(function () {
                    _this.toastrService.success('Los datos fueron cambiados con éxito.', '¡Bien!');
                    _this.userService.reloadUser().subscribe(function (user) {
                        _this.businessService.reloadBusiness().subscribe(function (business) {
                            _this.business = new Business(business);
                            if (document.getElementById('logo-header')) {
                                document.getElementById('logo-header').setAttribute('src', _this.business.logoSizes.header);
                            }
                            document.documentElement.style.setProperty('--primary-color', _this.business.primary_color);
                            document.documentElement.style.setProperty('--primary-light-color', hexToRgba(_this.business.primary_color || '#db38b7', 0.1));
                            document.documentElement.style.setProperty('--primary-dark-color', darkenHexColor(_this.business.primary_color || '#db38b7', 20));
                            document.documentElement.style.setProperty('--primary-color', _this.business.primary_color);
                            document.documentElement.style.setProperty('--primary-negative-color', _this.business.primary_negative_color);
                            document.documentElement.style.setProperty('--secondary-color', _this.business.secondary_color);
                            document.documentElement.style.setProperty('--secondary-negative-color', _this.business.secondary_negative_color);
                            document.documentElement.style.setProperty('--navbar-color', _this.business.navbar_color);
                            document.documentElement.style.setProperty('--navbar-negative-color', _this.business.navbar_negative_color);
                            _this.saving = false;
                            // FIXME window.location.reload();
                        });
                    });
                }, function (err) {
                    _this.saving = false;
                });
            }
            else {
                _this.toastrService.success('Los datos fueron cambiados con éxito.', '¡Bien!');
                _this.userService.reloadUser().subscribe(function (user) {
                    _this.saving = false;
                    window.location.reload();
                });
            }
        }, function (err) {
            _this.saving = false;
        });
    };
    ProfileComponent.prototype.calculator = function () {
        var _this = this;
        var calculatorBusiness = this.business ? this.business : this.user.business;
        var score_amount_ratio = this.form.get('business').get('score_amount_ratio').value;
        var dialogRef = this.dialogService.open(CalculatorComponent, { context: { score_amount_ratio: score_amount_ratio, business: calculatorBusiness } });
        dialogRef.componentRef.instance.onSetNewScoreAmountRatio.subscribe(function (new_score_amount_ratio) {
            _this.form.get('business').get('score_amount_ratio').setValue(new_score_amount_ratio);
            _this.form.get('business').get('score_amount_ratio').markAsTouched();
        });
        dialogRef.componentRef.instance.onSetGrowthPercentage.subscribe(function (growth) {
            _this.growth = growth;
        });
        dialogRef.onClose.subscribe(function () {
            dialogRef.componentRef.instance.onSetNewScoreAmountRatio.unsubscribe();
            dialogRef.componentRef.instance.onSetGrowthPercentage.unsubscribe();
        });
    };
    ProfileComponent.prototype.onFormValueChange = function (key, value) {
        this.form.get('business').get(key).setValue(value);
        this.form.get('business').get(key).markAsDirty();
    };
    ProfileComponent.prototype.onEditorFocus = function (index) {
        this.editorConfigs[index].showToolbar = true;
    };
    ProfileComponent.prototype.onEditorBlur = function (index) {
        this.editorConfigs[index].showToolbar = false;
    };
    return ProfileComponent;
}());
export { ProfileComponent };

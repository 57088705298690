import { environment } from '../../environments/environment';
import { Plan } from './plan.model';
var Business = /** @class */ (function () {
    function Business(init) {
        this.logo = 'placeholder.png';
        this.product_categories = [];
        this.hide_global_points = true;
        this.send_birthday_reward = false;
        this.sections = [];
        this.admin = null;
        this.promo_popup_enabled_admin = false;
        this.promo_popup_enabled = false;
        this.login_with_dni_text = false;
        this.login_with_cuit_text = false;
        this.show_facebook_login = false;
        this.show_google_login = false;
        this.hide_register = false;
        this.hide_movements_amount = false;
        this.send_account_created = true;
        this.avoid_sum_notification = false;
        this.update_levels_score_ratio = false;
        this.allow_min_amount = false;
        this.min_amount = 0;
        this.allow_min_branches_reads = false;
        this.min_branches_reads = 2;
        this.branches = [];
        this.allow_support_message = false;
        this.allow_accumulate_points_rules = false;
        this.make_client_email_mandatory_for_exchanges = false;
        Object.assign(this, init);
        this.setLogoSizes();
        this.setLogoMiniSizes();
        this.setLogoLoginSizes();
        this.setPromoImageMiniSizes();
        this.setLink();
        this.setBusinessTypes();
    }
    Business.prototype.setBusinessTypes = function () {
        if (!this.sections.some(function (s) { return s === 'Validar'; }))
            this.sections.push('Validar');
        if (!this.sections.some(function (s) { return s === 'Premios'; }))
            this.sections.push('Premios');
        if (!this.sections.some(function (s) { return s === 'Integraciones'; }))
            this.sections.push('Integraciones');
        if (!!this.hired_business_plan && !!this.hired_business_plan.business_plan) {
            var plan = new Plan(this.hired_business_plan.business_plan);
            if (plan.canExchangePoints) {
                if (!this.sections.some(function (s) { return s === 'Canjear puntos'; }))
                    this.sections.push('Canjear puntos');
            }
            if (plan.canOperatePoints) {
                if (!this.sections.some(function (s) { return s === 'Entregar Puntos'; }))
                    this.sections.push('Entregar Puntos');
            }
            if (plan.canExchangePoints || plan.canOperatePoints) {
                if (!this.sections.some(function (s) { return s === 'Movimientos'; }))
                    this.sections.push('Movimientos');
            }
            if (plan.allow_provider_mode) {
                if (!this.sections.some(function (s) { return s === 'Movimientos como proveedor'; }))
                    this.sections.push('Movimientos como proveedor');
            }
            if (!plan.hide_products_shop) {
                if (!this.sections.some(function (s) { return s === 'Tienda de premios'; }))
                    this.sections.push('Tienda de premios');
            }
            if (plan.allow_operators) {
                if (!this.sections.some(function (s) { return s === 'Operadores'; }))
                    this.sections.push('Operadores');
            }
            if (plan.allow_permissions_groups) {
                if (!this.sections.some(function (s) { return s === 'Permisos'; }))
                    this.sections.push('Permisos');
            }
            if (plan.allow_integrations) {
                if (!this.sections.some(function (s) { return s === 'Integraciones'; }))
                    this.sections.push('Integraciones');
            }
            if (plan.allow_levels) {
                if (!this.sections.some(function (s) { return s === 'Niveles'; }))
                    this.sections.push('Niveles');
            }
            if (plan.allow_surveys) {
                if (!this.sections.some(function (s) { return s === 'Encuestas'; }))
                    this.sections.push('Encuestas');
            }
        }
    };
    Object.defineProperty(Business.prototype, "isOnlyProvider", {
        get: function () {
            if (!!this.hired_business_plan && !!this.hired_business_plan.business_plan) {
                var plan = new Plan(this.hired_business_plan.business_plan);
                return plan.allow_provider_mode && !plan.canOperatePoints && !plan.canExchangePoints;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Business.prototype.setLogoSizes = function () {
        var _this = this;
        var sizes = {
            'header': 'AUTOx96',
            'thumb': '48x48_max',
            'small': '200x200_max',
            'standard': '400x300_max',
        };
        this.logoSizes = {};
        Object.keys(sizes).forEach(function (size) {
            _this.logoSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + (_this.logo || 'placeholder.png');
        });
    };
    Business.prototype.setLogoMiniSizes = function () {
        var _this = this;
        var sizes = {
            'header': 'AUTOx96',
            'thumb': '48x48_max',
            'small': '200x200_max',
            'standard': '400x300_max',
        };
        this.logoMiniSizes = {};
        if (this.logo_mini) {
            Object.keys(sizes).forEach(function (size) {
                _this.logoMiniSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + _this.logo_mini;
            });
        }
    };
    Business.prototype.setLogoLoginSizes = function () {
        var _this = this;
        var sizes = {
            'header': 'AUTOx96',
            'thumb': '48x48_max',
            'small': '200x200_max',
            'standard': '400x300_max',
        };
        this.logoLoginSizes = {};
        if (this.logo_login) {
            Object.keys(sizes).forEach(function (size) {
                _this.logoLoginSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + _this.logo_login;
            });
        }
    };
    Business.prototype.setPromoImageMiniSizes = function () {
        var _this = this;
        var sizes = {
            'header': 'AUTOx96',
            'thumb': '48x48_max',
            'small': '200x200_max',
            'standard': '400x300_max',
        };
        this.promo_mini_sizes = {};
        // TODO: Cambiar al bucket puntos-businesses-promos y sacarlo del de logos
        if (this.promo_image) {
            Object.keys(sizes).forEach(function (size) {
                _this.promo_mini_sizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + _this.promo_image;
            });
        }
    };
    Business.prototype.setLink = function () {
        if (!this.slug) {
            return this.link = environment.frontendBaseUrl;
        }
        var parts = environment.frontendBaseUrl.split('//');
        return this.link = parts[0] + '//' + this.slug + '.' + parts[1];
    };
    return Business;
}());
export { Business };
var HiredBusinessPlan = /** @class */ (function () {
    function HiredBusinessPlan(init) {
        this.suspended = true;
        Object.assign(this, init);
    }
    return HiredBusinessPlan;
}());
export { HiredBusinessPlan };

import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
// * estos son los permisos de los posibles fraudes detectados.
var PermissionPossibleFrauds = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionPossibleFrauds, _super);
    function PermissionPossibleFrauds() {
        return _super.call(this) || this;
    }
    return PermissionPossibleFrauds;
}(PermissionBase));
export { PermissionPossibleFrauds };

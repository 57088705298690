import { OperatorBranch } from './operator_branch.model';
var Operator = /** @class */ (function () {
    function Operator(init) {
        var _this = this;
        this.operator_branches = [];
        this.activeBranch = null;
        this.showDescription = function () {
            if (!_this.user) {
                return '';
            }
            var firstName = _this.user.first_name ? _this.user.first_name : '';
            var email = _this.user.email ? _this.user.email : '';
            var dni = _this.user.dni ? _this.user.dni : '';
            var lastName = _this.user.last_name ? _this.user.last_name : '';
            if (firstName.length > 0 || lastName.length > 0) {
                return firstName + " " + lastName;
            }
            else if (email.length > 0) {
                return "" + email;
            }
            return "" + dni;
        };
        Object.assign(this, init);
        if (this.operator_branches.length > 0) {
            this.activeBranch = new OperatorBranch(this.operator_branches[0]);
        }
    }
    Object.defineProperty(Operator.prototype, "permissions", {
        get: function () {
            return this.activeBranch && this.activeBranch.permission_group && this.activeBranch.permission_group.permissions ? this.activeBranch.permission_group.permissions : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Operator.prototype, "activeBranchAdmin", {
        get: function () {
            return this.activeBranch && !this.activeBranch.permission_group;
        },
        enumerable: true,
        configurable: true
    });
    Operator.prototype.canAccessTo = function (key) {
        var variables = {
            exchange: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.exchange.can_view),
            operate: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.operate.can_view),
            clients: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.clients.can_view),
            history: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.history.can_view),
            history_provider: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.history_provider.can_view),
            products: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.products.can_view),
            products_shop: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.products_shop.can_view),
            statistics: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.statistics.can_view),
            validate: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.validate.can_view),
            integrations: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.integrations.can_view),
            levels: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.levels.can_view),
            surveys: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.surveys.can_view),
            associated_frauds: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.associated_frauds.can_view),
            possible_frauds: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.possible_frauds.can_view),
            point_rules: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.point_rules.can_view),
        };
        return variables[key];
    };
    Object.defineProperty(Operator.prototype, "isAdmin", {
        get: function () {
            return this.role === 'admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Operator.prototype, "name", {
        get: function () {
            if (!this.user) {
                return '';
            }
            var firstName = this.user.first_name ? this.user.first_name : '';
            var email = this.user.email ? this.user.email : '';
            var dni = this.user.dni ? this.user.dni : '';
            var lastName = this.user.last_name ? this.user.last_name : '';
            if (firstName.length > 0 || lastName.length > 0) {
                return firstName + " " + lastName;
            }
            else if (email.length > 0) {
                return "" + email;
            }
            return "" + dni;
        },
        enumerable: true,
        configurable: true
    });
    return Operator;
}());
export { Operator };

import * as tslib_1 from "tslib";
import { PermissionBase } from './permission_base.model';
// * estos son los permisos de los detectores de fraudes asociados de un negocio.
// * es parte de las configuraciones de los detectores.
var PermissionAssociatedFrauds = /** @class */ (function (_super) {
    tslib_1.__extends(PermissionAssociatedFrauds, _super);
    function PermissionAssociatedFrauds() {
        return _super.call(this) || this;
    }
    return PermissionAssociatedFrauds;
}(PermissionBase));
export { PermissionAssociatedFrauds };
